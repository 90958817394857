import React from "react";
import LandingPage from "./pages/LandingPage";
import { Box, Container } from "@mui/material";

import "./App.css";

function App() {
  return (
    <Box sx={{ backgroundColor: "#07269B", color: "#fff" }}>
      <LandingPage />
    </Box>
  );
}

export default App;
