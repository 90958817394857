import { Box, Container, Typography } from "@mui/material";
import React from "react";

import heroImage from "../../assets/LandingPage/hero-image.png";

function Hero(props: any) {
  return (
    <>
      <Box
        sx={{
          position: "relative",
          color: "#fff",
          width: "100%",
          height: "130vh",
          display: "flex",
        }}
      >
        <Box
          component="img"
          alt="Image"
          src={heroImage}
          onLoad={props.handleImageLoad}
          sx={{
            height: "130vh",
            position: "absolute",
            width: "100%",
          }}
        />
        <Container
          sx={{
            position: "relative",
          }}
        >
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              gap: 5,
              flexDirection: "row-reverse",
              padding: "2rem 0",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "1rem", md: "1.1rem" },
                lineHeight: { xs: "18px", md: "20px" },
                fontWeight: 350,
                fontFamily: "CustomFont, sans-serif",
              }}
            >
              Contact
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "1rem", md: "1.1rem" },
                lineHeight: { xs: "18px", md: "20px" },
                fontWeight: 350,
                fontFamily: "CustomFont, sans-serif",
              }}
            >
              About
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "1rem", md: "1.1rem" },
                lineHeight: { xs: "18px", md: "20px" },
                fontWeight: 350,
                fontFamily: "CustomFont, sans-serif",
              }}
            >
              Service
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "1rem", md: "1.1rem" },
                lineHeight: { xs: "18px", md: "20px" },
                fontWeight: 350,
                fontFamily: "CustomFont, sans-serif",
              }}
            >
              Home
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexDirection: "column",
              padding: "1rem 0 0 0",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "2rem", md: "2.4rem" },
                lineHeight: { xs: "30px", md: "40px" },
                fontWeight: "bold",
                fontFamily: "CustomFont, sans-serif",
              }}
            >
              UAE’s #1 on-demand EV charging app.
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "1rem", md: "1.1rem" },
                lineHeight: { xs: "18px", md: "20px" },
                fontWeight: 350,
                fontFamily: "CustomFont, sans-serif",
              }}
            >
              Making your ride limitless, and every destination within reach.
            </Typography>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default Hero;
