import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";

import image from "../../assets/LandingPage/at-station.svg";

import NorthEastIcon from "@mui/icons-material/NorthEast";

function AtStationCharging() {
  return (
    <Box
      sx={{
        padding: { xs: "2rem 1rem", md: "4rem 2rem" },
      }}
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            alt="Image"
            src={image}
            sx={{
              width: { xs: "100%", md: 400 },
              paddingTop: { xs: "2rem", md: "0" },

              maxWidth: 400,
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Box
            sx={{
              fontFamily: "CustomFont, sans-serif",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "3.2rem", md: "5.2rem" },
                lineHeight: { xs: "45px", md: "90px" },
                fontWeight: 250,
                fontFamily: "CustomFont, sans-serif",
                paddingY: { xs: "20px", md: "25px" },
              }}
            >
              Charging at Station
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "1rem", md: "1.1rem" },
                lineHeight: { xs: "18px", md: "20px" },
                fontWeight: 350,
                opacity: "60%",
                fontFamily: "CustomFont, sans-serif",
              }}
            >
              Powered by a unified platform, Voltup's strategically located EV
              charging network is designed to optimize energy usage and minimize
              costs.
            </Typography>
            <Button
              variant="outlined"
              sx={{
                mt: 2,
                color: "#fff",
                fontFamily: "CustomFont, sans-serif",
                borderRadius: 0,
                borderColor: "rgba(255, 255, 255, 0.5)",
                textTransform: "none",
                width: "fit-content",
              }}
            >
              Find charging station near you{" "}
              <NorthEastIcon
                sx={{
                  ml: 1,
                  fontSize: "18px",
                  color: "#02D29E",
                }}
              />
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AtStationCharging;
